// custom setting
@key-color: #0290c7;
@img-path: 'img/okba';

// import
@import '../top';

// custom design
main {
  .content {
    &.mv {
    	height: ~"calc(80vh - 96px)";
    	background-image: url('../../img/okba/top/mv.png');
    	background-size: cover;
		@media only screen and (max-width: 600px){
			position: static;
			height: auto;
		}
    	.visualAndCopy {
    		flex-wrap: nowrap;
    		// flex-direction: column;
    		justify-content: flex-start;
    		align-items: center;
			@media only screen and (max-width: 600px){
				position: static;
			}
			.copy {
				position: static;
				.mainCopy {
					margin-bottom: 40px;
					font-size: 48px;
					line-height: 1.5em;
					letter-spacing: 20px;
					color: #fff;
					font-weight: normal;
					text-shadow: 4px 4px 4px rgba(0,0,0,.5);
					@media only screen and (max-width: 600px){
						font-size: 48px;
						text-align: left;
						display: inline-block;
					}
					@media only screen and (max-width: 500px){
						font-size: 40px;
					}
					@media only screen and (max-width: 430px){
						font-size: 30px;
					}
					@media only screen and (max-width: 360px){
						font-size: 25px;
					}
					span {
						font-size: 34px;
					}
				}
				a.serviceStart {
					margin-top: 0;
					margin-bottom: 0;
					@media only screen and (max-width: 600px){
						margin-bottom: 40px;
					}
				}
			}
    	}
    	.scroll {
    		left: 0!important;
    	  p {
    	    color: #fff;
    	  }
    	  span {
    	    border-left: 2px solid #fff;
    	    border-bottom: 2px solid #fff;
    	    }
    	}
    }
  	&.message {
		p {
			&.mainMessage {
				@media only screen and (max-width: 600px){
					font-size: 31px;
				}
				@media only screen and (max-width: 550px){
					font-size: 28px;
				}
				@media only screen and (max-width: 510px){
					font-size: 25px;
				}
				@media only screen and (max-width: 470px){
					font-size: 22px;
				}
				@media only screen and (max-width: 430px){
					font-size: 20px;
				}
				@media only screen and (max-width: 400px){
					font-size: 17px;
				}
				@media only screen and (max-width: 360px){
					font-size: 14px;
				}
				@media only screen and (max-width: 330px){
					font-size: 13px;
				}
			}
			&.subMessage {
				@media only screen and (max-width: 600px){
					margin-top: 15px;
					font-size: 12px;
					letter-spacing: 2px;
				}
				@media only screen and (max-width: 550px){
					letter-spacing: 1px;
				}
				@media only screen and (max-width: 510px){
					font-size: 11px;
				}
				@media only screen and (max-width: 470px){
					font-size: 10px;
				}
				@media only screen and (max-width: 430px){
					letter-spacing: 0.5px;
					-webkit-transform:scale(1) !important;
					-moz-transform:scale(1) !important;
					-ms-transform:scale(1) !important;
					-o-transform:scale(1) !important;
					transform:scale(1) !important;
				}
				@media only screen and (max-width: 400px){
					margin-top: 0;
					-webkit-transform:scale(0.9) !important;
					-moz-transform:scale(0.9) !important;
					-ms-transform:scale(0.9) !important;
					-o-transform:scale(0.9) !important;
					transform:scale(0.9) !important;
				}
				@media only screen and (max-width: 360px){
					-webkit-transform:scale(0.8) !important;
					-moz-transform:scale(0.8) !important;
					-ms-transform:scale(0.8) !important;
					-o-transform:scale(0.8) !important;
					transform:scale(0.8) !important;
				}
				@media only screen and (max-width: 346px){
					letter-spacing: 0;
					-webkit-transform:scale(0.8) !important;
					-moz-transform:scale(0.8) !important;
					-ms-transform:scale(0.8) !important;
					-o-transform:scale(0.8) !important;
					transform:scale(0.8) !important;
				}
			}
		}
	}
  	&.howto {
		p.attention {
			@media only screen and (max-width: 470px){
				font-size: 14px;
				height: 38px;
				line-height: 38px;
			}
			@media only screen and (max-width: 430px){
				font-size: 12px;
				height: 34px;
				line-height: 34px;
			}
			@media only screen and (max-width: 370px){
				font-size: 10px;
				height: 30px;
				line-height: 30px;
			}
		}
	}
	&.scheme {
		.schemeImage {
			@media only screen and (max-width: 600px){
				background-color: inherit;
				height: 100%;
			}
			img {
				@media only screen and (max-width: 600px){
					padding: 20px 15px;
					width: 100%;
				}
			}
		}
	}
  }
}

