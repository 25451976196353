.effectFade {
  opacity : 0;
  transition : all 1800ms;
  &.pos40 {
    transform : translate(0, 40px);
  }
  &.pos80 {
    transform : translate(0, 80px);
  }
  &.fast {
    transition : all 1200ms;
  }
}

//.effect-fade.effect-scroll {
.effectScroll {
  opacity : 1;
  transform : translate(0, 0) !important;
}
