// top.less
@sub-color: orange;
@width-menu: 240px;
@main-content-width: 1080px;
@main-border-color: #ddd;

html {
  scroll-behavior: smooth;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: #fff;
  @media only screen and (max-width: 600px){
    display: block;
  }
  .logo {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px){
      margin-bottom: 30px;
    }
    div {
      @media only screen and (max-width: 600px){
        width: 100%;
        text-align: center;
      }
      a {
        text-decoration: inherit;
        color: inherit;
      }
      p {
        margin-left: 6px;
        font-weight: bold;
        font-size: 16px;
        @media only screen and (max-width: 600px){
          margin-left: 0;
          font-size: 25px;
        }
        @media only screen and (max-width: 390px){
          margin-left: 0;
          font-size: 19px;
        }
        &.ja {
          span {
            font-size: 20px;
            @media only screen and (max-width: 600px){
              font-size: 35px;
            }
            @media only screen and (max-width: 390px){
              font-size: 28px;
            }
          }
        }
        &.en {
          font-size: 10px;
          letter-spacing: 1px;
          @media only screen and (max-width: 600px){
            font-size: 17px;
          }
          @media only screen and (max-width: 390px){
            font-size: 12px;
          }
        }
      }
    }
    img {
      @media only screen and (max-width: 600px){
        width: 100%;
        height: auto;
      }
    }
  }
  .btns {
    display: flex;
    a {
      margin-left: 24px;
      @media only screen and (max-width: 600px){
        height: 40px;
        line-height: 40px;
        width: 100%;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

main {
  min-width: 1200px;
  @media only screen and (max-width: 600px){
    min-width: 100%;
  }
  .content {
    position: relative;
    display: flex;
    .contentInner {
      width: 1200px;
      margin: 0 auto;
      @media only screen and (max-width: 600px){
        width: 100%;
      }
    }
    &.mv {
      justify-content: space-between;
      height: ~"calc(100vh - 96px)";
      background: #fff;
      @media only screen and (max-width: 600px){
        position: static;
        height: auto;
      }
      .bgLeft {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        height: ~"calc(100vh - 96px)";
        // height: 800px;
        width: 40%;
        background: #EAEAEA url("@{img-path}/top/mv_bg_02.png");
        @media only screen and (max-width: 600px){
          display: none;
        }
      }
      .visualAndCopy {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 600px){
          position: static;
        }
        img {
          position: relative;
          z-index: 30;
        }
        .copy {
          position: absolute;
          z-index: 40;
          left: 50%;
          top: 0;
          @media only screen and (max-width: 600px){
            position: static;
            left: inherit;
            width: 100%;
            text-align: center;
          }
          .mainCopy {
            font-size: 60px;
            font-weight: bold;
            line-height: 1.5em;
            letter-spacing: 20px;
            @media only screen and (max-width: 600px){
              font-size: 50px;
              text-align: left;
              display: inline-block;
            }
            @media only screen and (max-width: 500px){
              font-size: 40px;
            }
            @media only screen and (max-width: 430px){
              font-size: 30px;
            }
            @media only screen and (max-width: 360px){
              font-size: 25px;
            }
            strong {
              color: @key-color;
            }
          }
          .subCopy {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 4px;
            line-height: 1.7em;
            margin-top: 16px;
            @media only screen and (max-width: 600px){
              font-size: 21px;
              text-align: left;
              display: inline-block;
            }
            @media only screen and (max-width: 500px){
              font-size: 19px;
            }
            @media only screen and (max-width: 430px){
              font-size: 15px;
            }
            @media only screen and (max-width: 360px){
              font-size: 13px;
            }
          }
          a.serviceStart {
            margin-top: 40px;
            margin-bottom: 160px;
            @media only screen and (max-width: 600px){
              margin-bottom: 40px;
            }
            @media only screen and (max-width: 500px){
              width: 414px;
              height: 66px;
              line-height: 66px;
              margin-bottom: 40px;
            }
            @media only screen and (max-width: 430px){
              width: 342px;
              height: 52px;
              line-height: 52px;
              font-size: 13px;
            }
            @media only screen and (max-width: 360px){
              width: 306px;
              height: 46px;
              line-height: 46px;
              font-size: 12px;
            }
          }
        }
      }
      .scroll {
        position: absolute;
        left: ~"calc(50% - 12px)";
        bottom: 24px;
        @media only screen and (max-width: 600px){
          display: none;
        }
        p {
          padding-top: 80px;
          font-size: 10px;
          font-weight: bold;
          font-style: oblique;
          letter-spacing: 2px;
          color: #444;
        }
        span {
          position: absolute;
          top: 0;
          left: 50%;
          width: 24px;
          height: 24px;
          margin-left: -12px;
          border-left: 2px solid @key-color;
          border-bottom: 2px solid @key-color;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          -webkit-animation: sdb 2s infinite;
          animation: sdb 2s infinite;
          opacity: 0;
          box-sizing: border-box;
        }
        span:nth-of-type(1) {
          -webkit-animation-delay: 0s;
          animation-delay: 0s;
        }
        span:nth-of-type(2) {
          top: 16px;
          -webkit-animation-delay: .15s;
          animation-delay: .15s;
        }
        span:nth-of-type(3) {
          top: 32px;
          -webkit-animation-delay: .3s;
          animation-delay: .3s;
        }
        @-webkit-keyframes sdb {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes sdb {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
    &.message {
      align-content: center;
      height: 488px;
      background-image: url("@{img-path}/top/bg_02.png");
      background-position: right 0;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 600px){
        position: static;
        height: auto;
        background-position: 10% -50px;
        background-size: 150%;
      }
      p {
        text-align: center;
        &.mainMessage {
          margin-top: 104px;
          font-weight: bold;
          text-align: center;
          font-size: 60px;
          letter-spacing: 10px;
          @media only screen and (max-width: 600px){
            margin-top: 15px;
            font-size: 32px;
          }
          @media only screen and (max-width: 500px){
            font-size: 27px;
          }
          @media only screen and (max-width: 430px){
            font-size: 20px;
          }
          @media only screen and (max-width: 360px){
            font-size: 16px;
          }
          strong {
            color: @key-color;
          }
        }
        &.subMessage {
          margin-top: 24px;
          font-weight: bold;
          font-size: 15px;
          line-height: 2.6em;
          letter-spacing: 2px;
          color: #333;
          @media only screen and (max-width: 600px){
            margin-top: 15px;
            font-size: 10px;
            letter-spacing: 1px;
          }
          @media only screen and (max-width: 430px){
            margin-top: 0;
            letter-spacing: 0.5px;
            -webkit-transform:scale(0.8) !important;
            -moz-transform:scale(0.8) !important;
            -ms-transform:scale(0.8) !important;
            -o-transform:scale(0.8) !important;
            transform:scale(0.8) !important;
          }
          @media only screen and (max-width: 360px){
            margin-top: -10px;
            -webkit-transform:scale(0.7) !important;
            -moz-transform:scale(0.7) !important;
            -ms-transform:scale(0.7) !important;
            -o-transform:scale(0.7) !important;
            transform:scale(0.7) !important;
          }
        }
      }

    }
    &.howto {
      background: linear-gradient(lighten(@key-color, 60%), lighten(@key-color, 10%));
      @media only screen and (max-width: 600px){
        background: none;
      }
      .howtoInner {
        padding: 100px 0;
        background-repeat: no-repeat;
        background-image: url("@{img-path}/top/woman_01.png");
        background-position: right 0 bottom 0;
        @media only screen and (max-width: 600px){
          padding: 50px 0 0;
          background: none;
        }
      }
      p.attention {
        width: 830px;
        background: lighten(@key-color,10%);
        border-radius: 6px;
        height: 42px;
        line-height: 42px;
        color: #fff;
        letter-spacing: 1px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        @media only screen and (max-width: 600px){
          width: 90%;
          margin: 0 auto;
        }
        @media only screen and (max-width: 430px){
          font-size: 12px;
          height: 34px;
          line-height: 34px;
        }
      }
      .howtoBoxes {
        display: flex;
        @media only screen and (max-width: 600px){
          display: block;
        }
        .boxWrapper {
          @media only screen and (max-width: 600px){
            background: linear-gradient(lighten(@key-color, 50%), lighten(@key-color, 10%));
            width: 90%;
            margin: 20px auto;
            padding: 10px;
          }
          .left {
            height: 405px;
          }
          .right {
            height: 405px;
          }
        }
        .box {
          width: 400px;
          background: #fff;
          border: 2px solid @key-color;
          margin-top: 32px;
          margin-right: 32px;
          border-radius: 12px;
          padding: 32px;
          box-shadow: 0 0 8px rgba(0,0,0,.3);
          @media only screen and (max-width: 600px){
            width: 100%;
            margin: 0 auto;
          }
          .boxTitle {
            font-size: 20px;
            letter-spacing: 2px;
            font-weight: bold;
            text-align: center;
            padding: 0px 0 16px;
            border-bottom: 1px dashed #aaa;
            @media only screen and (max-width: 500px){
              font-size: 17px;
            }
            @media only screen and (max-width: 430px){
              font-size: 13px;
            }
            @media only screen and (max-width: 360px){
              font-size: 11px;
            }
            strong {
              color: @key-color;
              letter-spacing: 1px;
            }
          }
          .boxBody {
            padding: 16px 0;
            line-height: 1.75em;
            @media only screen and (max-width: 500px){
              font-size: 13px;
            }
            @media only screen and (max-width: 430px){
              font-size: 12px;
            }
            @media only screen and (max-width: 360px){
              font-size: 10px;
            }
          }
          a.btn {
            width: 100%;
            margin-top: 8px;
            @media only screen and (max-width: 500px){
              font-size: 13px;
            }
            @media only screen and (max-width: 430px){
              font-size: 12px;
            }
            @media only screen and (max-width: 360px){
              font-size: 10px;
            }
          }
        }
      }
      .guide {
        background: #fff;
        border: 2px solid @key-color;
        margin-top: 32px;
        border-radius: 12px;
        padding: 15px;
        box-shadow: 0 0 8px rgba(0,0,0,.3);
        width: 832px;
        text-align: center;
        @media only screen and (max-width: 600px){
          width: 90%;
          margin: 32px auto;
        }
        a {
          color: @key-color;
        }
      }
    }
    &.functions {
      padding: 100px 0;
      background-image: url("@{img-path}/top/bg_03.png");
      @media only screen and (max-width: 600px){
        padding: 40px 0;
      }
      .functionsTitle {
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 2px;
        text-align: center;
        @media only screen and (max-width: 600px){
          font-size: 22px;
        }
        @media only screen and (max-width: 550px){
          font-size: 20px;
        }
        @media only screen and (max-width: 520px){
          font-size: 18px;
        }
        @media only screen and (max-width: 470px){
          font-size: 16px;
        }
        @media only screen and (max-width: 420px){
          font-size: 14px;
        }
        @media only screen and (max-width: 370px){
          font-size: 13px;
        }
        @media only screen and (max-width: 340px){
          font-size: 12px;
        }
        strong {
          color: @key-color;
        }
      }
      .functionsMessage {
        width: 800px;
        margin: 32px auto 0;
        line-height: 1.75em;
        letter-spacing: 1px;
        @media only screen and (max-width: 600px){
          width: 100%;
          margin-top: 20px;
          padding: 0 10px;
        }
        @media only screen and (max-width: 370px){
          font-size: 13px;
        }
        @media only screen and (max-width: 340px){
          font-size: 12px;
        }
      }
      ul.functionsBoxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media only screen and (max-width: 600px){
          padding: 0 15%;
        }
        li {
          width: 560px;
          padding: 40px;
          margin-top: 40px;
          border: 3px solid @key-color;
          border-radius: 12px;
          text-align: center;
          background: #fff;
          @media only screen and (max-width: 600px){
            width: 100%;
            margin-top: 30px;
          }
          img {
            @media only screen and (max-width: 600px){
              width: 28.8%;
            }
          }
          p.title {
            margin-top: 12px;
            color: @key-color;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 2px;
            @media only screen and (max-width: 600px){
              font-size: 22px;
            }
            @media only screen and (max-width: 550px){
              font-size: 20px;
            }
            @media only screen and (max-width: 520px){
              font-size: 18px;
            }
            @media only screen and (max-width: 470px){
              font-size: 16px;
            }
            @media only screen and (max-width: 420px){
              font-size: 14px;
            }
            @media only screen and (max-width: 370px){
              font-size: 13px;
            }
            @media only screen and (max-width: 340px){
              font-size: 12px;
            }
          }
          p.body {
            margin-top: 16px;
            line-height: 1.75em;
            letter-spacing: 1px;
            @media only screen and (max-width: 370px){
              font-size: 13px;
            }
            @media only screen and (max-width: 340px){
              font-size: 12px;
            }
          }
        }
      }
    }
    &.scheme {
      padding: 72px 0;
      @media only screen and (max-width: 600px){
        padding: 30px 0 0;
      }
      .schemeTitle {
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 2px;
        text-align: center;
        @media only screen and (max-width: 600px){
          font-size: 36px;
        }
        @media only screen and (max-width: 500px){
          font-size: 28px;
        }
        @media only screen and (max-width: 430px){
          font-size: 24px;
        }
        @media only screen and (max-width: 360px){
          font-size: 22px;
        }
        strong {
          color: @key-color;
        }
      }
      .schemeMessage {
        width: 800px;
        margin: 32px auto 0;
        line-height: 1.75em;
        letter-spacing: 1px;
        @media only screen and (max-width: 600px){
          width: 100%;
          margin-top: 20px;
          padding: 0 30px;
          font-size: 14px;
        }
        @media only screen and (max-width: 500px){
          font-size: 12px;
        }
        @media only screen and (max-width: 430px){
          font-size: 11px;
        }
        @media only screen and (max-width: 360px){
          font-size: 10px;
        }
      }
      .schemeImage {
        margin-top: 40px;
        background: #E5E5E5;
        height: 580px;
        width: 100%;
        text-align: center;
        @media only screen and (max-width: 600px){
          margin-top: 0;
          background-color: inherit;
          height: 100%;
        }
        img {
          margin-top: 40px;
          @media only screen and (max-width: 600px){
            margin-top: 0;
            width: 100%;
          }
        }
      }
    }
    &.start {
      padding: 48px 0;
      background: @key-color;
      text-align: center;
      @media only screen and (max-width: 600px){
        padding: 30px 0;
      }
      p.text {
        color: #fff;
        font-size: 16px;
        letter-spacing: 2px;
        text-align: center;
        @media only screen and (max-width: 600px){
          padding: 0 20px;
          text-align: left;
        }
        @media only screen and (max-width: 500px){
          font-size: 15px;
        }
        @media only screen and (max-width: 430px){
          font-size: 14px;
        }
        @media only screen and (max-width: 360px){
          font-size: 13px;
        }
        &:before {
          content: '＼';
          font-size: 20px;
          @media only screen and (max-width: 600px){
            content: '';
          }
        }
        &:after {
          content: '／';
          font-size: 20px;
          @media only screen and (max-width: 600px){
            content: '';
          }
        }
      }
      a.btn.sizeXl {
        width: 70%;
        height: 80px;
        margin-top: 32px;
        background: #fff;
        line-height: 80px;
        border-radius: 16px;
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: bold;
        &:link, &:visited {
          color: @key-color;
          text-decoration: none;
        }
      }
      a.btn.getAccountBtn {
        @media only screen and (max-width: 600px){
          height: 60px;
          line-height: 60px;
          font-size: 16px;
        }
        @media only screen and (max-width: 500px){
          height: 50px;
          line-height: 50px;
          font-size: 15px;
        }
        @media only screen and (max-width: 430px){
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
        @media only screen and (max-width: 360px){
          font-size: 13px;
        }
      }
    }
  }
}

// parts
.btn {
  display: inline-block;
  text-align: center;
  &.keyColorBorder {
    height: 48px;
    width: 240px;
    line-height: 48px;
    border: 1px solid @key-color;
    border-radius: 6px;
    letter-spacing: 2px;
    font-weight: bold;
    transition: background .3s, color .3s;
    &:link, &:visited {
      color: @key-color;
      text-decoration: none;
    }
    &:hover {
      background: @key-color;
      color: #fff;
    }
  }
  &.keyColorFill {
    position: relative;
    height: 72px;
    width: 450px;
    line-height: 72px;
    text-align: center;
    font-size: 16px;
    background: @key-color;
    border-radius: 12px;
    &:link, &:visited {
      color: #fff;
      font-weight: bold;
      letter-spacing: 1px;
      text-decoration: none;
    }
    &:after {
      content: '＞';
      font-weight: normal;
      position: absolute;
      right: 32px;

    }
  }
}
